@mixin section-title {
	margin: 0 auto 1em;

	font-size: 4em;
	font-weight: 400;
	line-height: 1.06;
	letter-spacing: 0;
	text-align: center;

	@media (max-width: 767.9px) {
		margin: 0 auto 0.6em;

		font-size: 8.25em;
		font-weight: 400;
		line-height: 1.3;
		letter-spacing: 0;
		text-align: center;
	}
}

@mixin block-title {
	//32 36
	font-size: 2em;
	font-weight: 500;
	line-height: 1.12;
	letter-spacing: 0;
	text-align: center;

	@media (max-width: 767.9px) {
		font-size: 6.4em;
		font-weight: 400;
		line-height: 1.3;
		letter-spacing: 0;
		text-align: center;
	}

}

@mixin small-title {
	//30 36
	font-size: 2em;
	font-weight: 400;
	line-height: 1.12;
	letter-spacing: 0;
	text-align: left;

	@media (max-width: 767.9px) {
		font-size: 7.3em;
		font-weight: 400;
		line-height: 1.3;
	}
}

@mixin medium-text {
	//21 24
	font-size: 1.3125em;
	font-weight: 400;
	line-height: 1.15;
	letter-spacing: 0;
	text-align: left;
}

@mixin regular-text {
	//18 24
	font-size: 1.125em;
	font-weight: 400;
	line-height: 1.35;
	letter-spacing: 0;
	text-align: left;
}


@mixin small-text {
	font-size: .75em;
	font-weight: 400;
	line-height: 1.35;
	letter-spacing: 0;
	text-align: left;
}

$path: '../fonts/';

$Kaspersky: "Kaspersky",
Helvetica,
Arial,
sans-serif;

@mixin fontFaceKaspersky($name, $src, $weight, $style) {
	$source: $path+$src;

	@font-face {
		font-family: $name;
		src: url($source + '.woff2') format('woff2'),
		url($source + '.woff') format('woff'),
		url($source + '.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
	}
}

@include fontFaceKaspersky('Kaspersky', 'Kaspersky_Sans_Display_Light', 300, normal);
@include fontFaceKaspersky('Kaspersky', 'Kaspersky_Sans_Display_Regular', 400, normal);
@include fontFaceKaspersky('Kaspersky', 'Kaspersky_Sans_Display_Medium', 500, normal);
@include fontFaceKaspersky('Kaspersky', 'Kaspersky_Sans_Display_SemiBold', 600, normal);
@include fontFaceKaspersky('Kaspersky', 'Kaspersky_Sans_Display_Bold', 700, normal);

