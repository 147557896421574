@import '../../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';
@import 'mixins';
@import 'variables';
@import 'custom';
@import "normalize";

body {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #1D1D1B;
}

#root {
  max-width: 1920px;
  margin: 0 auto;
  font-family: 'Kaspersky', Helvetica, sans-serif;
  font-size: calc(1.115vw + 0.0073px);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #1D1D1B;
  color: #ffffff;

  @media (min-width: 1440px) {
    font-size: 16px;
  }

  a {
    text-decoration: none;
    color: #ffffff;
    transition: color .25s ease-in-out;

    &:hover {
      color: #23D1AE;
    }
  }

  .container {
    max-width: 1220px;
    margin: 0 auto;
    //height: 100%;
  }

  .purple-text {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: 0;
    text-align: left;
    color: #8233FF;
  }

  .green-text {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: 0;
    text-align: left;
    color: #7EFF33;
  }

  .regular-text {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.35;
    letter-spacing: 0;
    text-align: left;
  }

  .section-divider {
    svg {
      width: 100%;
      height: auto;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .sm-title {
    @include small-title;
  }

  .favorite-item {
    cursor: pointer;

    &-add {
      svg {
        path {
          fill: #23D1AE;
          stroke: #23D1AE;
          transition: all .25s ease-in-out;
        }
      }
    }
  }

  .header {

    &__show {

      .header-catalog {
        z-index: 10;
        //background-color: #1c364d;
        opacity: 0;
        &__item {
          color: #FFFFFF;
        }
      }

      .header__nav {
        width: calc(100% + 1.5rem);
        left: -0.75rem;
        transform: translateX(0);
        &-list {
          display: flex;
          flex-direction: column;

        }
      }
    }

    &__wrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 4em;

      @media (max-width: 767.9px) {
        height: 10em;
      }
    }

    &__logo {
      width: 10.7em;
      margin-top: 0.4em;
      svg {
        width: 9.7em;
        height: auto;
        @media (max-width: 767.9px) {
          width: 26.7em;
        }
      }
    }

    &__nav {
      height: 100%;
      transition: all .15s linear;

      @media (max-width: 767.9px) {
        z-index: 1;
        background-color: #1D1D1B;
        position: absolute;
        top: 10em;
        left: 0;
        width: auto;
        height: fit-content;
        transform: translateX(-200%);
        padding: 4em;
      }
      &-list {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 2.2em;

        &_item {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;

          &-active {
            &:before {
              content: '';
              position: absolute;
              bottom: 0;

              left: -0.5em;
              height: 0.25em;
              width: calc(100% + 1em);
              background: #23D1AE;
            }
          }

          a {
            display: block;
            font-style: normal;
            font-weight: 600;
            font-size: .875em;
            line-height: 4;

            padding: 0;
            @media (max-width: 767.9px) {
              font-size: 2.875em;
            }
          }
        }
      }
    }

    &__menu {
      display: none;
      z-index: 2;

      @media (max-width: 767.9px) {
        display: flex;
        align-items: center;
      }

      svg {
        width: 6em;
        height: 6em;
      }
      .open-menu {
        display: flex;
        align-items: center;
      }

      .close-menu {
        display: none;
      }
    }

    &__menu-close {
      .open-menu {
        display: none;
      }

      .close-menu {
        display: block;
      }
    }
  }

  .hero {
    //background-color: #8233FF;
    padding: 0;
    &__img {
      img {
        width: 100%;
      }
    }
  }

  .section-1 {
    padding: 4em 0 5em;

    @media (max-width: 767.9px) {
      padding: 9em 0 5em;
    }

    &__wrapper {
      margin-bottom: 5.5em;
    }

    &__title {
      @include section-title;

      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      h4 {
        font-weight: 500;
      }
      @media (max-width: 767.9px) {
        margin: 0 auto 0.6em;

      }

      .back-button {
        z-index: 0;
        align-items: center;
        display: flex;
        position: absolute;
        //width: 0.6em;

        right: 100%;
        //overflow-y: hidden;
        height: 0.6em;
        width: 0.6em;
        transform: translateX(-0.25em);
        border-radius: 3em;

        overflow: hidden;
        //transition: all .25s ease-in-out;
        cursor: pointer;
        @media (max-width: 767.9px) {
          right: auto;
          left: -15%;
          width: 0.7em;
          height: 0.7em;
          border-radius: 0;
        }
        &:hover {
          width: max-content;
          @media (max-width: 767.9px) {
            width: 100%;
          }
        }
        &:hover .back-button__img {
          background-color: #444;
        }
        &:hover span{
          transform: translateX(0);
          opacity: 1;
          width: 16em;
          background-color: #444;
          padding: 0 4em 0 0;
          color: #FFFFFF;
          @media (max-width: 767.9px) {
            transform: none;
          }
        }

        span {
          z-index: 1;
          display: flex;
          align-items: center;
          width: 16em;
          height: 100%;
          padding: 0 4em 0 0;
          text-align: right;
          font-size: .19em;
          line-height: 1.1;
          opacity: 0;
          transform: translateX(100%);
          transition: all .25s ease-in-out;

          @media (max-width: 767.9px) {
            display: none;

          }
        }

        &__img {
          z-index: 2;
          display: flex;
          align-items: center;
          position: absolute;
          right: 0;
          transition: all .15s ease-in-out;
          border-radius: 3em;
          background-color: #1D1D1B;
          //&:hover {
          //  background-color: #444;
          //}

          @media (max-width: 767.9px) {
            border-radius: 0;
          }
          svg {
            //z-index: 1;
            //margin-right: -0.075em;
            border-radius: 3em;
            @media (max-width: 767.9px) {
              width: 0.7em;
              height: 0.7em;
              border-radius: 0;
            }
          }
        }

      }

    }

    &__item {
      display: flex;
      flex-direction: column;

      &-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1em;
        margin-bottom: 1em;

        font-size: .875em;
        font-weight: 600;
        line-height: 1.14;
        letter-spacing: 0.1em;
        text-align: left;

        @media (max-width: 767.9px) {
          justify-content: center;

          font-size: 2.77em;
          font-weight: 600;
          line-height: 1.6;
          /* or 14px */

          display: flex;
          align-items: center;
          letter-spacing: 0.1em;

        }

        &_date {
          text-transform: uppercase;

          color: #AEAEAE;
        }

        &_time {
          text-transform: uppercase;

          color: #AEAEAE;
        }
      }

      &-title {
        margin-bottom: 0.4em;

        font-size: 2.25em;
        font-weight: 500;
        line-height: 1.11;
        letter-spacing: 0;
        text-align: left;

        h4 {
          font-weight: 500;
        }

        @media (max-width: 767.9px) {
          letter-spacing: 0;
          text-align: center;
          font-size: 7.3em;
          font-weight: 400;
          line-height: 1.3;
        }
      }

      &-description {
        margin-bottom: 0.5em;


        font-size: 1.125em;
        font-weight: 300;
        line-height: 1.22;
        letter-spacing: 0;
        text-align: left;
        order: 0;
        color: #999999;

        @media (max-width: 767.9px) {
          margin-bottom: 1.3em;
          order: 1;

          font-size: 3.2em;
          font-style: normal;
          font-weight: 300;
          line-height: 1.3;
          /* or 20px */

          text-align: center;

          /* New/999999 */

          color: #999999;
        }
      }

      &-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.5em;
        margin-bottom: 1.25em;

        @media (max-width: 767.9px) {
          justify-content: center;
        }
        &_favorite {
          &:hover {
            .section-1__item-link_img {
              svg {
                path {
                  fill: #FFFFFF;
                }
              }
            }
          }
        }

        &_share {
          transition: color .25s ease-in-out;
          &:hover {
            .section-1__item-link_img {
              svg {
                path {
                  stroke: #FFFFFF;
                }
              }
            }
          }
          &-text {
            transition: all .125s linear;
          }
          svg {
            path {
              transition: all .25s linear;
            }
          }
        }

        &_download {
          //transition: all .25s ease-in-out;
          &:hover {
            .section-1__item-link_img {
              svg {
                path {
                  fill: #FFFFFF;
                }
              }
            }
          }
          &-text {
            transition: all .125s linear;
          }

          svg {
            path {
              transition: all .25s linear;
              @media (max-width: 767.9px) {
                fill: #7EFF33;
                stroke: #AEAEAE;
              }
            }
          }
        }

        &_player {
          display: none!important;

          @media (max-width: 767.9px) {
            display: flex!important;
          }

          .section-1__item-link_img {
            // padding-left: .2em;

            svg {
              width: 3.4em;
              height: 3.4em;
              padding: 1em;
              @media (max-width: 767.9px) {


                width: 12.4em;
                height: 12.4em;
                padding: 4em;
                background: #37505052;
              }

            }
          }

        }

        &_player.play {

          .section-1__item-link_img {
            // padding-left: .2em;

            svg {
              width: 3.4em;
              height: 3.4em;
              padding: 1em;
              @media (max-width: 767.9px) {


                width: 12.4em;
                height: 12.4em;
                padding: 4em;
                background: #37505052;
              }

            }
          }

          .play-button {
            display: block;
          }

          .pause-button {
            display: none;
            margin-left: 0.2em;
          }
        }
      }

      &-link {

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.7em;

        font-size: .875em;
        font-weight: 600;
        line-height: 1.14;
        letter-spacing: 0;
        text-align: left;
        cursor: pointer;
        color: #AEAEAE;
        //transition: all .25s ease-in-out;
        &:hover {
          color: #FFFFFF;

          .section-1__item-links_download {
            &-text {
              color: #FFFFFF;
            }
          }

          .section-1__item-links_share {
            &-text {
              color: #FFFFFF;
            }
          }


        }

        @media (max-width: 767.9px) {
          flex-direction: column;
          width: 25%;
        }

        &_img {
          width: 1.4em;
          height: 3.4em;
          @media (max-width: 767.9px) {
            border-radius: 50%;
            overflow: hidden;
            width: 12.4em;
            height: 12.4em;

          }

          svg {
            width: 2.4em;
            height: 3.4em;
            padding: 1em 1em 1em 0;
            @media (max-width: 767.9px) {


              width: 12.4em;
              height: 12.4em;
              padding: 3.5em;
              background: #37505052;
            }

            path {
              @media (max-width: 767.9px) {
                stroke: #7EFF33;
              }
            }
          }
        }

        span {
          //font-size: .85em;
          //font-weight: 700;
          //line-height: 1.2;
          white-space: nowrap;
          @media (max-width: 767.9px) {
            color: #aeaeae;
            align-items: center;

            display: flex;


            font-size: 3.1em;
            font-weight: 700;
            line-height: 1.8;
          }

        }

      }

      &-player {
        display: block;
        @media (max-width: 767.9px) {

          opacity: 0;
          visibility: hidden;
          z-index: 2;
          width: 100%;
          height: auto;
          position: fixed;
          bottom: 0;
          left: 0;
          transform: translateY(200%);
          transition: all .25s linear;
        }
      }
      &-player.hide {
        opacity: 0;
        visibility: hidden;
        transform: translateY(200%);

      }
      &-player.play {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);

      }
    }

    &__platforms {
      margin-bottom: 2.75em;

      &-title {
        margin-bottom: 1.3em;

        font-size: 2em;
        font-weight: 500;
        line-height: 1.12;
        letter-spacing: 0;
        text-align: center;



        @media (max-width: 767.9px) {
          margin-bottom: 0.8em;

          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 120%;

          text-align: center;
          font-feature-settings: 'liga' off;


          color: #FFFFFF;
        }
      }

      &-links {
        position: relative;
        z-index: 1;
        &:before {
          content: '';
          z-index: -1;
          position: absolute;
          top: 2.5em;
          background: #375050;
          opacity: 0.32;
          border-radius: 1em;
          width: 100%;
          height: calc(100% - 2em);
          @media (max-width: 767.9px) {
            content: none;
          }
        }
      }

      &-wrapper {

      }

      &-link {
        margin-bottom: 2em;
        cursor: pointer;

        @media (max-width: 767.9px) {
          padding-right: 2em;
          padding-left: 2em;
        }

        &_container {
          display: flex;
          align-items: center;
          gap: 1em;
          background: #375050;
          border-radius: 1em;
          padding: 1em;



          @media (max-width: 767.9px) {
            background: #375050;
            border-radius: 4em;
            align-items: center;
            gap: 4em;
            padding: 4em 3em 4em 3em;
            display: flex;
          }
        }

        &_title {
          font-size: 1em;
          font-weight: 600;
          line-height: 1.25;
          letter-spacing: 0;
          text-align: left;


          @media (max-width: 767.9px) {
            text-align: left;
            font-size: 3.75em;
            font-weight: 600;
            line-height: 1.25;
          }
        }
      }
    }

    &__links {
      &-item {
        margin-bottom: 0;
        @media (max-width: 767.9px) {
          margin-bottom: 3em;
        }
        &_title {
          margin-bottom: 0.5em;

          font-style: normal;
          font-weight: 400;
          font-size: 1.125em;
          line-height: 1.33;

          color: #999999;


          @media (max-width: 767.9px) {
            font-size: 2.65em;
            font-weight: 300;
            line-height: 1.3;
            letter-spacing: 0;
            text-align: left;
          }

        }

        &_link {
          display: flex;
          align-items: center;
          gap: 0.5em;
          @media (max-width: 767.9px) {
            gap: 1.5em;
          }
          a {
            font-size: 1.3125em;
            font-weight: 600;
            line-height: 1.14em;
            letter-spacing: 0;
            text-align: right;
            transition: color .25s ease-in-out;
            &:hover {
              color: #23D1AE;
            }

            @media (max-width: 767.9px) {
              font-size: 3.3em;
              font-weight: 700;
              line-height: 1.2;
              /* identical to box height, or 17px */

              display: flex;
              align-items: center;
              text-align: right;

              /* New/White */

              color: #FFFFFF;
            }
          }

          svg {
            margin-top: 0.25em;
            width: 2em;
            height: auto;

            @media (max-width: 767.9px) {
              width: 3em;
              margin-top: 0.5em;
            }
          }
        }
      }
    }

    &__text {
      @include regular-text;

      margin-bottom: 2.5em;

      p {
        margin-bottom: 0.75em;
      }
    }

    &__img {
      position: relative;
      display: flex;
      justify-content: center;
      height: fit-content;
      margin-bottom: 0;
      padding-right: 2.1666em;
      @media (max-width: 767.9px) {
        margin-bottom: 5em;
      }

      img {
        border-radius: 1em;
        width: 100%;
        height: auto;

        @media (max-width: 767.9px) {
          border-radius: 4em;

        }
      }

      &-title {
        position: absolute;
        bottom: 1.5em;
        font-weight: 700;
        font-size: 1em;
        line-height: 1;

        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #1D1D1B;

        @media (max-width: 767.9px) {
          font-weight: 700;
          font-size: 3.75em;
          line-height: 1;

          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: #1D1D1B;

        }
      }
    }

    a {
      //color: #ffffff;
      text-decoration: none;
    }
  }

  .section-2 {
    padding: 4em 0 5em;

    background-image: url("../../public/img/s2-background@2x.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    @media (max-width: 767.9px) {
      padding: 8.5em 0 14em;

      background-image: url("../../public/img/s2-background-mob@2x.png");
    }
    .speakers {
      @media (max-width: 767.9px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__title {
      @include section-title;
      margin: 0 auto 0.375em;

      h4 {
        font-weight: 500;
      }

      @media (max-width: 767.9px) {
        margin: 0 auto 0.6em;
      }
    }

    &__description {
      margin-bottom: 3em;

      font-size: 1.3125em;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: 0;
      text-align: center;

      @media (max-width: 767.9px) {
        margin-bottom: 1.5em;

        font-size: 3.2em;
        font-style: normal;
        font-weight: 300;
        line-height: 1.25;
        letter-spacing: 0;
        text-align: center;
      }

    }

    &__speakers {
      .swiper-wrapper {
        //margin-left: -8em;
      }

      &-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        &_img {
          margin-bottom: 2em;

          img {
            width: 17.5em;
            height: auto;
            @media (max-width: 767.9px) {
              width: 46.5em;
            }
          }
        }

        &_title {
          margin-bottom: 0.375em;
          @include block-title;
          white-space: nowrap;
        }

        &_description {
          padding: 0 1em;

          font-size: 1.125em;
          font-weight: 400;
          line-height: 1.22;
          letter-spacing: 0;
          text-align: center;
          color: #999999;
          @media (max-width: 767.9px) {
            text-align: center;
            color: #999;
            font-size: 3.2em;
            font-style: normal;
            font-weight: 300;
            line-height: 1.4;
          }
        }
      }


    }

    .speakers {
      &-swiper {
        overflow: visible;
        @media (max-width: 767.9px) {
          overflow: hidden;
        }

        &-wrapper {
          justify-content: center;
          gap: 1em;
          @media (max-width: 767.9px) {
            justify-content: normal;
            gap: 0;
          }
          .swiper-slide {
            width: 21.5em;
            @media (max-width: 767.9px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .section-3 {
    padding: 4em 0 5em;
    @media (max-width: 767.9px) {
      padding: 9.5em 0 10em;
    }

    &__title {
      @include section-title;
      margin: 0 auto 0.875em;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;

      h4 {
        font-weight: 500;
      }
      @media (max-width: 767.9px) {
        margin: 0 auto 0.6em;

      }
    }

    &__wrapper {

    }

    &__block {
      margin-bottom: 3.5em;
    }

    &__block.active {
      .popup-shadow {

      }
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: 0.3em;

      @media (max-width: 767.9px) {
        padding-right: 9em;
        justify-content: center;
      }

      &-info {
        display: flex;
        align-items: center;
        gap: 1em;
        margin-bottom: 1em;

        font-size: .875em;
        font-weight: 600;
        line-height: 1.14;
        letter-spacing: 0.1em;
        text-align: left;
        @media (max-width: 767.9px) {
          order: 1;

          font-size: 2.77em;
          font-weight: 600;
          line-height: 1.6;
          /* or 14px */


        }

        &_date {

          display: block;
          text-transform: uppercase;

          /* New/AEAEAE */

          color: #AEAEAE;
          @media (max-width: 767.9px) {
            display: none;
          }
        }

        &_time {
          text-transform: uppercase;

          /* New/AEAEAE */

          color: #AEAEAE;
        }
      }

      &-title {
        display: flex;
        align-items: center;
        gap: 0.5em;

        margin-bottom: 0.4em;

        font-size: 2em;
        font-weight: 400;
        line-height: 1.12;
        letter-spacing: 0;
        text-align: left;
        h4 {
          font-weight: 500;
        }
        @media (max-width: 767.9px) {
          font-size: 3.75em;
          font-weight: 400;
          line-height: 1.3;
        }

        a {
          display: flex;
          align-items: flex-start;
          gap: 0.5em;
          width: max-content;

          @media (max-width: 767.9px) {
            display: flex;
            align-items: center;
            gap: 0.5em;
            width: fit-content;
          }
        }

        svg {
          margin-top: 0.4em;
          width: 1em;
          height: auto;

          @media (max-width: 767.9px) {
            display: none;
          }
        }
      }

      &-description {
        margin-bottom: 0.6em;

        font-size: 1em;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: 0;
        text-align: left;
        color: #999999;
        @media (max-width: 767.9px) {
          display: none;
        }
      }

      &-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.5em;
        margin-bottom: 1.35em;

        @media (max-width: 767.9px) {
          display: none;  
          justify-content: center;
        }
        &_favorite {
          &:hover {
            .section-3__item-link_img {
              svg {
                path {
                  fill: #FFFFFF;
                }
              }
            }
          }
        }

        &_share {
          transition: color .25s ease-in-out;
          &:hover {
            .section-3__item-link_img {
              svg {
                path {
                  stroke: #FFFFFF;
                }
              }
            }
          }
          &-text {
            transition: all .125s linear;
          }
          svg {
            path {
              transition: all .25s linear;
            }
          }
        }

        &_download {
          //transition: all .25s ease-in-out;
          &:hover {
            .section-3__item-link_img {
              svg {
                path {
                  fill: #FFFFFF;
                }
              }
            }
          }
          &-text {
            transition: all .125s linear;
          }

          svg {
            path {
              transition: all .25s linear;
              @media (max-width: 767.9px) {
                fill: #7EFF33;
                stroke: #AEAEAE;
              }
            }
          }
        }

        &_player {
          display: none!important;

          @media (max-width: 767.9px) {
            display: flex!important;
          }

          .section-1__item-link_img {
            padding-left: .2em;

            svg {
              width: 3.4em;
              height: 3.4em;
              padding: 1em;
              @media (max-width: 767.9px) {


                width: 12.4em;
                height: 12.4em;
                padding: 4em;
                background: #37505052;
              }

            }
          }

        }

        &_player.play {

          .section-1__item-link_img {
            padding-left: .2em;

            svg {
              width: 3.4em;
              height: 3.4em;
              padding: 1em;
              @media (max-width: 767.9px) {


                width: 12.4em;
                height: 12.4em;
                padding: 4em;
                background: #37505052;
              }

            }
          }

          .play-button {
            display: block;
          }

          .pause-button {
            display: none;
            margin-left: 0.2em;
          }
        }
      }

      &-link {

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.7em;

        font-size: .875em;
        font-weight: 600;
        line-height: 1.14;
        letter-spacing: 0;
        text-align: left;
        cursor: pointer;
        color: #AEAEAE;
        //transition: all .25s ease-in-out;
        &:hover {
          color: #FFFFFF;

          .section-1__item-links_download {
            &-text {
              color: #FFFFFF;
            }
          }

          .section-1__item-links_share {
            &-text {
              color: #FFFFFF;
            }
          }


        }

        @media (max-width: 767.9px) {
          flex-direction: column;
          width: 25%;
        }

        &_img {
          width: 1.4em;
          height: 3.4em;
          @media (max-width: 767.9px) {
            border-radius: 50%;
            overflow: hidden;
            width: 12.4em;
            height: 12.4em;

          }

          svg {
            width: 2.4em;
            height: 3.4em;
            padding: 1em 1em 1em 0;
            @media (max-width: 767.9px) {


              width: 12.4em;
              height: 12.4em;
              padding: 3.5em;
              background: #37505052;
            }

            path {
              @media (max-width: 767.9px) {
                stroke: #7EFF33;
              }
            }
          }
        }

        span {
          //font-size: .85em;
          //font-weight: 700;
          //line-height: 1.2;
          white-space: nowrap;
          @media (max-width: 767.9px) {
            color: #aeaeae;
            align-items: center;

            display: flex;


            font-size: 3.1em;
            font-weight: 700;
            line-height: 1.8;
          }

        }

      }

      &-player {
        display: block;
        @media (max-width: 767.9px) {

          opacity: 0;
          visibility: hidden;
          z-index: 2;
          width: 100%;
          height: auto;
          position: fixed;
          bottom: 0;
          left: 0;
          transition: all .25s linear;
        }

        .player {
          .progress {
            width: 70%;
            @media (max-width: 767.9px) {
              width: 100%;
            }
          }
        }
      }
      &-player.hide {
        opacity: 0;
        visibility: hidden;
      }
      &-player.play {
        opacity: 1;
        visibility: visible;
      }

      &-show {
        display: none;

        @media (max-width: 767.9px) {
          position: absolute;
          right: 2.75em;
          display: flex;
          align-items: center;
          justify-content: center;


        }
      }
    }

    &__img {
      position: relative;
      display: flex;
      justify-content: center;
      height: fit-content;
      margin-bottom: 0;

      @media (max-width: 767.9px) {
        margin-bottom: 0;
      }

      img {
        border-radius: 1em;
        width: 16.875em;
        height: auto;

        @media (max-width: 767.9px) {
          border-radius: 1em;
          width: 100%;
        }
      }

      &-title {
        position: absolute;
        bottom: 1.5em;
        font-weight: 700;
        font-size: .75em;
        line-height: 1;

        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #1D1D1B;

        @media (max-width: 767.9px) {
          font-weight: 700;
          font-size: 1em;
          line-height: 1;

          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: #1D1D1B;

        }
      }
    }

    &__popup {
      display: flex;
      z-index: 3;
      position: fixed;
      left: 0;
      bottom: 0;

      flex-direction: column;
      padding-top: 4em;
      padding-bottom: 6em;
      padding-left: 2em;
      padding-right: 2em;
      background: #1D1D1B;

      border-radius: 5em 5em 0 0;
      transform: translateY(200%);
      transition: transform .25s linear;

      .popup-shadow {
        height: 100%;
        width: 100%;
        opacity: .6;
        z-index: -1;
        cursor: pointer;
        background-color: #041229;
        transition: opacity .25s;
        position: fixed;
        top: 0;
        left: 0;
      }

      .popup-container {
        display: flex;
        flex-direction: column;
      }

      &-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1em;
        margin-bottom: 1em;

        font-size: .875em;
        font-weight: 600;
        line-height: 1.14;
        letter-spacing: 0.1em;
        text-align: left;

        @media (max-width: 767.9px) {
          justify-content: center;

          font-size: 2.77em;
          font-weight: 600;
          line-height: 1.6;
          /* or 14px */

          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          /* New/AEAEAE */

          color: #AEAEAE;
        }

        &_date {

        }

        &_time {

        }
      }

      &-title {
        margin-bottom: 0.4em;

        font-size: 2.25em;
        font-weight: 500;
        line-height: 1.11;
        letter-spacing: 0;
        text-align: left;

        @media (max-width: 767.9px) {
          letter-spacing: 0;
          text-align: center;
          font-size: 7.3em;
          font-weight: 400;
          line-height: 1.3;
        }
      }

      &-description {
        margin-bottom: 1.3em;

        font-size: 1.125em;
        font-weight: 400;
        line-height: 1.22;
        letter-spacing: 0;
        text-align: left;
        order: 0;
        @media (max-width: 767.9px) {
          order: 1;

          font-size: 3.2em;
          font-style: normal;
          font-weight: 300;
          line-height: 1.3;
          /* or 20px */

          text-align: center;

          /* New/999999 */

          color: #999999;
        }
      }

      &-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.5em;
        margin-bottom: 2.5em;

        @media (max-width: 767.9px) {
          justify-content: center;
        }
        &_favorite {
          &:hover {
            svg {
              path {
                stroke: #23D1AE;
              }
            }
          }
        }

        &_share {
          transition: color .25s ease-in-out;

          &:hover {
            color: #23D1AE;
          }
        }

        &_download {
          transition: color .25s ease-in-out;

          &:hover {
            color: #23D1AE;
          }

          svg {
            path {
              @media (max-width: 767.9px) {
                fill: #7EFF33;
                stroke: #AEAEAE;
              }
            }
          }
        }

        &_player {
          display: none!important;

          @media (max-width: 767.9px) {
            display: flex!important;
          }

          .section-1__item-link_img {
            padding-left: .2em;

            svg {
              width: 3.4em;
              height: 3.4em;
              padding: 1em;
              @media (max-width: 767.9px) {


                width: 12.4em;
                height: 12.4em;
                padding: 4em;
                background: #37505052;
              }

            }
          }

        }
      }

      &-link {

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.7em;

        font-size: .875em;
        font-weight: 600;
        line-height: 1.14;
        letter-spacing: 0;
        text-align: left;
        cursor: pointer;
        @media (max-width: 767.9px) {
          flex-direction: column;
          width: 25%;
        }

        &_img {
          width: 2.4em;
          height: 3.4em;
          @media (max-width: 767.9px) {
            border-radius: 50%;
            overflow: hidden;
            width: 12.4em;
            height: 12.4em;

          }

          svg {
            width: 3.4em;
            height: 3.4em;
            padding: 1em;
            @media (max-width: 767.9px) {


              width: 12.4em;
              height: 12.4em;
              padding: 3.5em;
              background: #37505052;
            }

            path {
              @media (max-width: 767.9px) {
                stroke: #7EFF33;
              }
            }
          }
        }

        span {
          //font-size: .85em;
          //font-weight: 700;
          //line-height: 1.2;
          @media (max-width: 767.9px) {
            color: #aeaeae;
            align-items: center;

            display: flex;


            font-size: 3.1em;
            font-weight: 700;
            line-height: 1.8;
          }

        }

        .play-button {
          display: block;
          // margin-left: 0.2em;

        }

        .pause-button {
          display: none;

        }

      }

      &-link.play {
        svg {
          width: 1em;
          height: 1em;

          @media (max-width: 768px) and (max-width: 1023.98px) {
            width: 6em;
            height: 6em;
          }

          @media screen and (max-width: 767.98px) {
            width: 100%;
            height: 100%;
            /* background: #37505052; */
            padding: 3.5em;
          }

          path {
            transition: fill .25s ease;
          }
          &:hover {
            path {
              fill: #ffffff;
            }
          }
        }



        .play-button {
          display: none;
        }

        .pause-button {
          display: block;
        }
      }

      &-player {
        display: none;
        z-index: 2;
        width: 100%;
        height: auto;
        position: fixed;
        bottom: 0;
        left: 0;



      }

      &-player.play {
        display: none;
      }
    }

    &__popup.show {
      transform: translateY(0);
    }

    &__more {
      display: flex;
      align-items: center;
      gap: .75em;
      width: fit-content;
      margin: 0 auto;
      cursor: pointer;
      @media (max-width: 767.9px) {
        gap: 1.75em;
      }
      &:hover svg {
        circle {
          fill: #00A88E;
        }

        //transform: rotate(90deg);
      }

      svg {
        circle {
          transition: all .25s;
        }
        @media (max-width: 767.9px) {
          width: 3.5em;
          height: 3.5em;
        }
      }

      &-text {
        font-style: normal;
        font-size: 1.3125em;
        font-weight: 600;
        line-height: 1.14em;

        color: #23D1AE;
        @media (max-width: 767.9px) {
          font-size: 3.3125em;
        }
      }
    }
  }

  .section-4 {
    padding: 1.5em 0 1.2em;
    @media (max-width: 767.9px) {
      background-color: #1D1D1B;
      padding: 5.5em 0 5.2em;
    }
    &__wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }


    &__info {
      display: flex;
      align-items: center;

      @media (max-width: 767.9px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }

      p {
        @include small-text;
        color: #cccccc;

        @media (max-width: 767.9px) {
          font-size: 2.55em;
          margin-bottom: 0.8em;
        }
      }

      span {
        display: inline;
        color: #ffffff;
        padding: 0 0.5em;
        @media (max-width: 767.9px) {
          display: none;
        }
      }

      a {
        @include small-text;
        color: #23D1AE;
        transition: all .25s ease;
        @media (max-width: 767.9px) {
          font-size: 2.85em;
          margin-bottom: 0.8em;
        }
        &:hover {
          color: #00A88E;
        }
      }
    }
    &__logo {
      width: 10.7em;

      @media (max-width: 767.9px) {
        width: 37em;
        margin-bottom: 5em;
      }
      svg {
        width: 9.7em;
        height: auto;
        @media (max-width: 767.9px) {
          width: 35.7em;
        }
      }
    }

    &__links {
      display: flex;
      align-items: center;
      gap: 0.5em;
      justify-content: flex-end;
      @media (max-width: 767.9px) {
        position: absolute;
        top: 1em;
        right: 0;
        gap: 4em;
      }
      a {
        &:hover svg path{
          fill: #23D1AE;
        }
        svg {
          width: 1.5em;
          height: auto;
          @media (max-width: 767.9px) {
            width: 5em;
          }
          path {
            transition: all .25s ease;
          }
        }
      }
    }
  }

  .podcast {
    padding-top: 3em;
    padding-bottom: 3em;
    @media (max-width: 767.9px) {
      padding-top: 0;
      padding-bottom: 0;
    }
    .section-1__title {

      @media (max-width: 767.9px) {
        margin: 1em auto 0.6em;
        width: 69%;
      }
    }
  }

  .line-title {
    position: relative;
    z-index: 1;
    svg {
      z-index: -1;
      position: absolute;
      bottom: -0.3em;
      left: -0.2em;
      width: 110%;
      height: auto;
    }
  }

}

.player {
  display: flex;
  align-items: center;
  gap: 2em;
  position: relative;
  width: 100%;
  background: #375050;
  border-radius: 2.625em;
  padding: 0.6em 0.6em;
  @media (max-width: 767.9px) {
    padding: 0.6em 2.6em 8em;
    min-height: 19em;
    height: fit-content;
    border-radius: 0;
    flex-direction: column;
  }
  &-info {
    display: none;
    @media (max-width: 767.9px) {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 3.5em 1em 0.5em 1em;
      width: 100%;
    }

    &__title {
      //16 19
      padding: 0 1em;
      font-size: 3.82em;
      font-weight: 700;
      line-height: 1.1875;
      letter-spacing: 0;
      text-align: center;

    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 1.45em;
      background-color: #1D1D1B;
      svg {
        width: 4.75em;
        height: 4.75em;
        path {
          stroke: #7eff33;
        }
      }
    }

    &__favorite {
      background-color: transparent;
      svg {
        display: none;
      }

    }
  }
}

.controls {
  width: 3em;
  height: 3em;
  background-color: #1d1d1b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767.9px) {
    width: 11em;
    height: 11em;
    position: absolute;
    top: -21%;
    left: 44%;
    border-radius: 50%;
    background: #1D1D1B;

  }

  &_play {
    svg {
      width: 1em;
      height: 1em;
    }

  }
}

.overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.overlay_hidden {
  display: none;
}

.overlay_error {
  background: #ffcccc;
  color: #ff0000;
}

.controls_play {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover svg path{
    fill: #ffffff;
    @media screen and (max-width: 767.98px) {
      fill: rgb(126, 255, 51);
    }
  }
  @media screen and (max-width: 767.98px) {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  svg {
    width: 1em;
    height: 1em;

    @media (max-width: 768px) and (max-width: 1023.98px) {
      width: 6em;
      height: 6em;
    }

    @media screen and (max-width: 767.98px) {
      width: 100%;
      height: 100%;
      /* background: #37505052; */
      padding: 3.5em;
    }

    path {
      transition: fill .25s ease;
    }
    &:hover {
      path {
        fill: #ffffff;
      }
    }
  }

  .play-button {
    display: block;
    margin-left: 0.2em;

  }

  .pause-button {
    display: none;

  }
}

.player_playing {
  .controls_play {
    .play-button {
      display: none;
    }

    .pause-button {
      display: block;
    }
  }
}

.player_playing .controls_play {
  // background: #9DBAFF;
  svg {
    fill: blue;
  }
}

.progress {
  display: flex;
  align-items: center;
  position: relative;
  width: 66%;
  height: 0.5em;
  border-radius: 1.75em;
  border: none;
  margin: 0 0 0 5em;

  background-color: #1D1D1B;
  cursor: pointer;
  @media screen and (max-width: 767.98px) {
    width: 100%;
    margin: 0 2em;
  }

  &_position {
    position: absolute;
    left: -6.6em;

    font-weight: 600;
    font-size: .875em;
    line-height: 1.14;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    @media screen and (max-width: 767.98px) {
      left: 0;
      top: 0.6em;

      font-weight: 700;
      font-size: 2.8em;
      line-height: 120%;
      /* or 14px */

      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #FFFFFF;
    }
  }

  &_duration {
    position: absolute;
    right: -6em;

    font-style: normal;
    font-weight: 600;
    font-size: .875em;
    line-height: 1.14;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #A5BEBE;
    @media screen and (max-width: 767.98px) {
      right: 0;
      top: 0.6em;

      font-weight: 700;
      font-size: 2.8em;
      line-height: 120%;
      /* or 14px */

      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #FFFFFF;
    }
  }

  //@media (max-width: 768px) and (max-width: 1023.98px) {
  //  height: 1.5em;
  //}
  //
  //@media screen and (max-width: 767.98px) {
  //  width: 90%;
  //  height: 3em;
  //
  //  margin: 0.5em 0;
  //}



}

.progress_loaded, .progress_current {
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 1.75em 0 0 1.75em ;
  cursor: pointer;
}

.progress_loaded {
  background-color: #556E6E;
  opacity: .3;
  z-index: 1;

  //transform: matrix(1, 2, 3, 4, 5, 6);
}

.progress_current {
  position: relative;
  background: linear-gradient(270deg, #7EFF33 0%, #23D1AE 100%);
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: -50%;
    right: -0.5em;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: #7EFF33;
    box-shadow: 0 0 20px #385151;
    cursor: pointer;


    @media screen and (max-width: 767.98px) {
      content: "";
      width: 2em;
      height: 2em;
      cursor: pointer;
      background-color: #7eff33;
      border-radius: 50%;
      position: absolute;
      top: -125%;
      right: -1em;
      box-shadow: 0 0 20px #385151;
    }


    &:hover {
      background-color: #ffffff;
    }
  }

}

.volume {
  display: block;
  position: absolute;
  right: 0;
  width: 9%;
  top: 0;
  height: 100%;
  border: 1px #ccc solid;
}

.volume_bar {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #535BC3;
}



//попап

.popup {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 112;
  top: 0;
  left: 0;

  .popup-shadow {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #1D1D1B;
    opacity: 0.64;
    z-index: 1;
    cursor: pointer;
    transition: opacity 0.25s ease;
  }

  .form-title {
    margin: 0 0 0.25em;

    font-size: 48px;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
    @media screen and (max-width: 767.98px) {
      font-size: 8em;
    }
  }

  .form-description {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;
    color: #FFFFFF;
    @media screen and (max-width: 767.98px) {
      margin-bottom: 1em;
      font-size: 3.75em;
      font-weight: 500;
      line-height: 1.32;
    }
  }

  .form-swiper {
    position: relative;
    padding: 0 2.5em;
    @media screen and (max-width: 767.98px) {
      margin-bottom: 1em;
    }
    &__container {
      padding: 2em 0 2.5em;

    }

    &__navigation {
      @media screen and (max-width: 767.98px) {
        display: none;
      }
      top: 47% !important;
      &_prev {
        left: 0!important;
      }

      &_next {
        transform: rotate(180deg);
        right: 0!important;
      }
    }

    &__item {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      &:hover {
        color: #ffffff!important;
      }

      @media screen and (max-width: 767.98px) {
        max-width: 100%;
      }
    }

    &__wrapper {
      justify-content: center;
      gap: 1em;
      @media screen and (max-width: 767.98px) {
        gap: 1em;
      }
      [tooltip] {
        position: relative; /* opinion 1 */
        max-width: 3em;
        @media screen and (max-width: 767.98px) {
          // max-width: 100%;
          min-width: 10em!important;
        }
      }

      /* Applies to all tooltips */
      [tooltip]::before,
      [tooltip]::after {
        text-transform: none; /* opinion 2 */
        font-size: .9em; /* opinion 3 */
        line-height: 1;
        user-select: none;
        pointer-events: none;
        position: absolute;
        display: none;
        opacity: 0;

      }
      [tooltip]::before {
        content: '';
        //border: 5px solid transparent; /* opinion 4 */
        z-index: 1001; /* absurdity 1 */
      }
      [tooltip]::after {
        content: attr(tooltip); /* magic! */

        /* most of the rest of this is opinion */
        //font-family: Helvetica, sans-serif;
        text-align: center;

        /*
          Let the content set the size of the tooltips
          but this will also keep them from being obnoxious
          */
        min-width: 3em;
        max-width: 21em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0.2em 1em;

        //box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
        background: #556E6E;
        border-radius: .5em;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        z-index: 1000; /* absurdity 2 */
      }

      /* Make the tooltips respond to hover */
      [tooltip]:hover::before,
      [tooltip]:hover::after {
        display: block;
        opacity: 1;
        @media screen and (max-width: 767.98px) {
          display: none;
        }
      }

      /* don't show empty tooltips */
      [tooltip='']::before,
      [tooltip='']::after {
        display: none !important;
      }

      /* FLOW: UP */
      [tooltip]:not([flow])::before,
      [tooltip][flow^="up"]::before {
        bottom: 100%;
        border-bottom-width: 0;
        border-top-color: #333;
      }
      [tooltip]:not([flow])::after,
      [tooltip][flow^="up"]::after {
        bottom: 100%;
      }
      [tooltip]:not([flow])::before,
      [tooltip]:not([flow])::after,
      [tooltip][flow^="up"]::before,
      [tooltip][flow^="up"]::after {
        left: auto;
        transform: translateY(-0.5em);
      }
    }
  }

  .form-input {
    position: relative;
    display: flex;
    align-items: center;

    &__item {
      width: 100%;
      height: 3em;
      border: 2px solid #FFFFFF;
      padding: 0 5em 0 1em;
      background: #375050;

      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      color: #FFFFFF;
      @media screen and (max-width: 767.98px) {
        font-size: 3.15em;
        padding: 0 3em 0 1em;
      }
      &:focus {
        border-radius: 0;
        //border-color: transparent;
        outline: none;
      }
    }
    &__img {
      position: absolute;
      right: 1em;
      cursor: pointer;
      width: 1.35em;
      height: 1.35em;

      @media screen and (max-width: 767.98px) {
        right: 3em;
        width: 4.35em;
        height: 4.35em;
      }

      &:hover .form-input__img-copy {
        path {
          fill: #23D1AE;;
        }
      }
      svg {
        position: absolute;
        right: 0;
        width: 1.35em;
        height: 1.35em;
        transition: all .15s ease-in-out;

        @media screen and (max-width: 767.98px) {
          width: 4.35em;
          height: 4.35em;
        }
        path {
          transition: all .25s ease-in-out;
        }
      }

      &-copy {
        opacity: 1;
      }

      &-copied {
        opacity: 0;
      }
    }

    &__img.copied {
      .form-input__img-copy {
        opacity: 0;
      }

      .form-input__img-copied {
        opacity: 1;
      }
    }
  }


}
.popup.active {
  display: block;
}
.page-shadow {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #041229;
  opacity: 0.6;
  z-index: 9;
  cursor: pointer;
  transition: opacity 0.25s ease;
}
//.popup-shadow:hover,
//.page-shadow:hover {
//  opacity: 0.4;
//}
.page-shadow.active {
  display: block;
}
.page-shadow.active .bottom_header {
  cursor: pointer;
}
.popup > .box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.form-wrapper {
  width: 41%;
  max-width: 60%;
  z-index: 20;
  background: #375050;
  border-radius: 1em;
  margin: auto;
  padding: 2em 2em;
  display: none;
  position: relative;

  @media (max-width: 767.9px) {
    width: 90%;
    max-width: 90%;
    border-radius: 1.75em;
    padding: 8.5em 3.5em;
  }


  //.form-title {
  //  color: #15A69D;
  //  margin: 0 0 2em;
  //}
  //
  //.form-description {
  //  margin-bottom: 2em;
  //  p {
  //    margin: 0 0 0.8em;
  //  }
  //}

  &_links {
    display: flex;
    align-items: center;

    gap: 2em;

    button {

      width: 11em;
      display: flex;
      justify-content: center;
    }
  }
}
.form-wrapper.contacts {
  display: block;
  background-color: #FFFFFF;
  margin: auto;
  padding: 1.5em 0;
  border-radius: 0.35em;
  width: 30em;

  @media (max-width: 767.9px) {
    width: 90%;
    max-width: 90%;
    border-radius: 1.75em;
    padding: 8.5em 3.5em;
  }

  .form-title {
    display: flex;
    justify-content: center;
    gap: 2em;
    color: #333333;
    margin: 0;

    img {
      @media (max-width: 767.9px) {
        width: 10em;
        height: auto;
      }
    }
  }
}
.form-wrapper.active {
  display: block;
}

.close {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  transition: all 0.5s ease;
  transform: rotate(0deg);
  transform-origin: center;
  cursor: pointer;

  @media (max-width: 767.9px) {
    height: 5.5em;
    width: 5.5em;
    top: 3.5em;
    right: 3.5em;
  }
}
.close:hover:before,
.close:hover:after {
  background-color: #23D1AE;
}
.close:before,
.close:after {
  content: "";
  display: inline-block;
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
  transform-origin: center;
  background-color: #AEAEAE;
  transition: all .15s ease;
}
.close:before {
  transform: rotate(-45deg);
}
.close:after {
  transform: rotate(45deg);
}








